@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #202020;
    color: white;
}

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background: black;
}

::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #fff;
}